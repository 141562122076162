<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col key="listUtilizadores" :cols="12">
          <v-card v-if="utilizadores" dark>
            <v-card-title>
              Utilizadores
              <v-btn
                title="Criar Novo Utilizador"
                v-if="permissao()"
                class="mx-2"
                fab
                dark
                small
                color="#7366aa"
                @click="selecionado={};addUtilizador=true">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="pesquisaUtilizadores"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar">
              </v-text-field>
            </v-card-title>
            <v-data-table
              v-model="utilizadorselected"
              :headers="utilizadorHeader"
              :search="pesquisaUtilizadores"
              :custom-filter="filtroDaBusca"
              :items="utilizadores"
              :loading = "loaders.userTable"
              loading-text="A Carregar...">
              dark
              hide-default-footer>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:[`item.role`]="{ item }">{{item.role.name}}</template>
              <template v-slot:[`item.editar`]="{ item }">
                <v-btn
                  v-if="permissao()"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="#7366aa"
                  @click="editarUtilizador(item)">
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br><br>
    <div class="text-center">
      <v-dialog v-model="addUtilizador" style="z-index:9999" width="500">
        <v-card :disabled = "loaders.savingUser">
          <v-card-title class="headline grey lighten-2" primary-title>Utilizador</v-card-title>
          <v-card-text>
            <v-form ref="formUtilizadores" lazy-validation>
              <v-text-field v-model="selecionado.nome" label="Nome" :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="selecionado.apelido" label="Apelido" :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="selecionado.cargo" label="Cargo" :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="selecionado.username" label="Nº Utilizador" :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="selecionado.email" label="Email" :rules="[rules.required]"></v-text-field>
              <v-select
                v-model="selecionado.permissao"
                :items="permissoes"
                label="Grupo de Permissões"
                :rules="[rules.required]">
              </v-select>
              <v-text-field
                v-model="selecionado.senha"
                :type="showPass ? 'text' : 'password'"
                label="Senha"
                :rules="[rules.required]"
                @click:append="showPass = !showPass"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'">
              </v-text-field>
              <v-btn
                color="success"
                class="mr-4"
                @click="validateForm"
                :loading = "loaders.savingUser"
                :disabled = "loaders.savingUser">
                Salvar
              </v-btn>

              <v-btn color="error" class="mr-4" @click="addUtilizador = false">
                Cancelar
              </v-btn>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
//import { icon, latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LGeoJson,
  LIcon,
  LPolyline,
} from "vue2-leaflet";

export default {
  API_URL: process.env.VUE_APP_API_URL,
  name: "Report",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LGeoJson,
    LIcon,
    LPolyline,
  },
  data: () => ({
    showPass: false,
    addUtilizador: false,
    utilizadorselected: [],
    utilizadores: [],
    pesquisaUtilizadores: "",
    selecionado: { role: { name: "" } },
    myStyle: {
      weight: 2,
      color: "rgb(142, 140, 140)",
      fillColor: "#5f5f5f",
    },
    rules: {
      required: (value) => !!value || "Preenchimento obrigatório",
    },
    permissoes: ["Administrador", "Motorista", "Visualizador"],
    utilizadorHeader: [
      { text: "Nome", value: "nome", align: 'center' },
      { text: "Apelido", value: "apelido", align: 'center' },
      { text: "Cargo", value: "cargo", align: 'center' },
      { text: "Nº Utilizador", value: "username", align: 'center' },
      { text: "Email", value: "email", align: 'center' },
      { text: "Permissão", value: "role", align: 'center' },
      { text: "editar", value: "editar", sortable: true, align: 'center' },
    ],
    loaders: {
      savingUser: false,
      userTable: false,
    }
  }),
  methods: {
    editarUtilizador(item) {
      this.selecionado = item;
      this.selecionado.permissao = item.role.name;
      this.addUtilizador = true;
    },
    filtroDaBusca(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        item.valoresDePesquisa.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').indexOf(search.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) !== -1
       // nomalize(NFD) e replace() estao a remover tudo que nao for 0-9 e o que nao for A-Z a-z, ou seja, removendo acentos e caracteres especiais no campo de pesquisa//
      );
    },
    validateForm() {
      if (this.$refs.formUtilizadores.validate()) {
        this.saveUtilizador();
      }
    },
    saveUtilizador() {
      this.loaders.savingUser = true;
      if (this.selecionado.permissao == "Administrador") {
        this.selecionado.role = { id: 1 };
      } else if (this.selecionado.permissao == "Motorista") {
        this.selecionado.role = { id: 3 };
      } else {
        this.selecionado.role = { id: 4 };
      }
      if (this.selecionado.senha.length > 0) {
        this.selecionado.password = this.selecionado.senha;
      }
      if (this.selecionado.id)
        this.$http
          .put("users/" + this.selecionado.id, this.selecionado, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`
            },
          })
          .then((response) => {
            this.getUtilizadores();
            this.$refs.formUtilizadores.reset();
            this.addUtilizador = false;
            this.loaders.savingUser = false;
          })
          .catch((error) => {
            console.log(error);
            this.loaders.savingUser = false;
          });
      else
        this.$http
          .post("users/", this.selecionado, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`
            },
          })
          .then((response) => {
            this.getUtilizadores();
            this.$refs.formUtilizadores.reset();
            this.addUtilizador = false;
            this.loaders.savingUser = false;
          })
          .catch((error) => {
            console.log(error);
            this.loaders.savingUser = false;
          });
    },
    permissao() {
      return this.getObjeto("user").role.name == "Administrador";
    },
    getUtilizadores() {
      this.loaders.userTable = true;
      this.$http.get("users", {
        headers: {
          Authorization: `Bearer ${this.getObjeto("token")}`
        },
      }).then((response) => {
        this.utilizadores = response.data;
        this.utilizadores.forEach((i) => {
                  i.valoresDePesquisa =
                    i.nome + " " + i.apelido + " " + i.cargo + " " + i.username +
                    " " + i.email + " " + i.permissao
        })
        this.loaders.userTable = false;
      }).catch((error) => {
        console.log(error);
        this.loaders.userTable = false;
      });
    },

    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
    convertData(data) {
      return new Date(data).toLocaleString();
    },
  },
  created() {
    this.getUtilizadores();
  },
};
</script>
